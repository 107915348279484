define("ember-presentational-table/components/presentational-table/head/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <thead class={{@classes.thead}} ...attributes>
    {{yield
      (hash Row=(component "presentational-table/head/row" classes=@classes onSort=@onSort sort=@sort))
    }}
  </thead>
  */
  {
    "id": "fqqwqwID",
    "block": "[[[11,\"thead\"],[16,0,[30,1,[\"thead\"]]],[17,2],[12],[1,\"\\n  \"],[18,5,[[28,[37,1],null,[[\"Row\"],[[50,\"presentational-table/head/row\",0,null,[[\"classes\",\"onSort\",\"sort\"],[[30,1],[30,3],[30,4]]]]]]]]],[1,\"\\n\"],[13]],[\"@classes\",\"&attrs\",\"@onSort\",\"@sort\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/head/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});