define("ember-presentational-table/components/presentational-table/body/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tbody class={{@classes.tbody}} ...attributes>
    {{#each @data as |item|}}
      {{yield
        (hash Row=(component "presentational-table/body/row" classes=@classes))
        item
      }}
    {{/each}}
  </tbody>
  */
  {
    "id": "bU0uZ1XB",
    "block": "[[[11,\"tbody\"],[16,0,[30,1,[\"tbody\"]]],[17,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"    \"],[18,5,[[28,[37,3],null,[[\"Row\"],[[50,\"presentational-table/body/row\",0,null,[[\"classes\"],[[30,1]]]]]]],[30,4]]],[1,\"\\n\"]],[4]],null],[13]],[\"@classes\",\"&attrs\",\"@data\",\"item\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/body/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});