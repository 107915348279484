define("ember-link/helpers/link", ["exports", "@ember/component/helper", "@ember/debug", "@ember/service", "ember-link/link"], function (_exports, _helper, _debug, _service, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let LinkHelper = (_dec = (0, _service.inject)('link-manager'), (_class = class LinkHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "linkManager", _descriptor, this);
    }
    /**
     * Normalizes the positional and named parameters passed to this helper.
     *
     * @param positional [route?, ...models?, query?]
     * @param named { route?, models?, model?, query? }
     */
    normalizeLinkParams(positional, named) {
      (true && !(!('queryParams' in named)) && (0, _debug.assert)(`You provided 'queryParams', but the parameter you mean is just 'query'.`, !('queryParams' in named)));
      (true && !(!('routeName' in named)) && (0, _debug.assert)(`You provided 'routeName', but the parameter you mean is just 'route'.`, !('routeName' in named)));
      if (named.fromURL) {
        (true && !(!['route', 'models', 'model', 'query'].some(name => named[name])) && (0, _debug.assert)(`When specifying a serialized 'fromURL' ('${named.fromURL}'), you can't provide any further 'LinkParams'.`, !['route', 'models', 'model', 'query'].some(name => named[name])));
        return this.linkManager.getLinkParamsFromURL(named.fromURL);
      }
      (true && !(!(positional[0] && named.route)) && (0, _debug.assert)(`Either pass the target route name as a positional parameter ('${positional[0]}') or pass it as a named parameter ('${named.route}').`, !(positional[0] && named.route)));
      (true && !(Boolean(positional[0] || named.route)) && (0, _debug.assert)(`Neither specified the target route name as a positional or named parameter ('route').`, Boolean(positional[0] || named.route)));
      const positionalQueryParameters = positional.length > 0 && (0, _link.isQueryParams)(positional[positional.length - 1]) ? positional[positional.length - 1] : undefined;
      (true && !(!positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))) && (0, _debug.assert)(`Query parameters either need to be specified as the last positional parameter or as the named 'query' parameter.`, !positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))));
      (true && !(!(named.query && positionalQueryParameters)) && (0, _debug.assert)(`Either specify query parameters as the last positional parameter ('${positional[positional.length - 1]}') or as the named 'query' parameter ('${named.query}').`, !(named.query && positionalQueryParameters)));
      (true && !(!(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))) && (0, _debug.assert)(`Either specify models as positional parameters, as the named 'models' parameter, or as the named 'model' parameter as a short hand for a single model.`, !(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))));
      (true && !(!(named.models && named.model)) && (0, _debug.assert)(`Either pass multiple 'models' ('${named.models}') or pass a single 'model' ('${named.model}'). `, !(named.models && named.model)));
      return {
        route: named.route ?? positional[0],
        models: Array.isArray(named.models) ? named.models : named.model ? [named.model] : positional.length > 1 ? positional.slice(1, positionalQueryParameters ? -1 : undefined) : undefined,
        query: named.query ?? positionalQueryParameters,
        onTransitionTo: named.onTransitionTo,
        onReplaceWith: named.onReplaceWith
      };
    }

    /**
     * Normalizes and extracts the `UILinkParams` from the named params.
     *
     * @param named { preventDefault? }
     */
    normalizeUIParams(named) {
      return {
        preventDefault: named.preventDefault ?? true
      };
    }
    compute(positional, named) {
      const linkParams = this.normalizeLinkParams(positional, named);
      const uiParams = this.normalizeUIParams(named);
      return this.linkManager.createUILink(linkParams, uiParams);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "linkManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LinkHelper;
});