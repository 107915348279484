define("ember-data-record-meta/-private/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertMessage = assertMessage;
  function assertMessage(message) {
    return `ember-data-record-meta: ${message}`;
  }
});