define("ember-presentational-table/components/presentational-table/head/row/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class={{@classes.theadRow}} ...attributes>
    {{yield
      (hash Cell=(component "presentational-table/head/row/cell" classes=@classes onSort=@onSort sort=@sort))
    }}
  </tr>
  */
  {
    "id": "6w7SBv75",
    "block": "[[[11,\"tr\"],[16,0,[30,1,[\"theadRow\"]]],[17,2],[12],[1,\"\\n  \"],[18,5,[[28,[37,1],null,[[\"Cell\"],[[50,\"presentational-table/head/row/cell\",0,null,[[\"classes\",\"onSort\",\"sort\"],[[30,1],[30,3],[30,4]]]]]]]]],[1,\"\\n\"],[13]],[\"@classes\",\"&attrs\",\"@onSort\",\"@sort\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/head/row/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});