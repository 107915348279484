define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "actions": {
      "abort": "Abort",
      "activate": "Activate",
      "addDevice": "Add device",
      "addJourney": "Add journey",
      "addRoute": "Add route",
      "addUser": "Add account",
      "cancel": "Cancel",
      "confirm": "Confirm",
      "create": "Create",
      "deactivate": "Deactivate",
      "delete": "Delete",
      "discharge": "Discharge",
      "edit": "Edit",
      "ignore": "Ignore",
      "merge": "Merge",
      "post": "Post",
      "resetPassword": "Reset password",
      "resolve": "Resolve",
      "showLog": "Show log",
      "split": "Split",
      "update": "Update",
      "uploadParkings": "Upload parkings"
    },
    "appTitle": "Ahlers AMS",
    "auth": {
      "redirecting": "Redirecting...",
      "signOut": "Sign out",
      "signingIn": "Signing in...",
      "signingOut": "Signing out..."
    },
    "commandLogs": {
      "type": {
        "custom": "CUSTOM COMMAND",
        "reboot": "DEVICE REBOOT",
        "update_version": "FIRMWARE UPDATE"
      }
    },
    "components": {
      "actionFeedback": {
        "labelError": "Error",
        "labelProcessing": "Processing",
        "labelSuccess": "Success"
      },
      "alarmStatus": {
        "muted": {
          "description": "An event has caused the sound alarm to go off but the sound has been muted. Use the switch to turn the sound on again.",
          "title": "Sound alarm is muted"
        },
        "on": {
          "description": "An event has caused the sound alarm to go off. You can use the switch to mute the sound.",
          "title": "Sound alarm is going off"
        }
      },
      "buttonFilter": {
        "active": "Remove filters",
        "inactive": "Filter"
      },
      "capturePhoto": {
        "button": "Capture photo",
        "labelError": "Failed",
        "labelProcessing": "Capturing",
        "labelSuccess": "Captured",
        "text": "Capturing photo"
      },
      "editJourneyForm": {
        "clientDetailsTitle": "Client details",
        "trucksTitle": "Trucks"
      },
      "eventLog": {
        "alert": {
          "statusIcon": {
            "tooltipMessage": {
              "ignored": {
                "dispatcher": "Ignored by {name} at {updatedAt}",
                "dispatcherUnknown": "Ignored at {updatedAt}"
              },
              "resolved": {
                "automatically": "Resolved automatically at {updatedAt}",
                "dispatcher": "Resolved by {name} at {updatedAt}",
                "dispatcherUnknown": "Resolved at {updatedAt}"
              }
            }
          }
        }
      },
      "fileUploadButton": {
        "uploading": "Uploading",
        "uploadingFile": "Uploading '<b>'{fileName}'</b>'.",
        "uploadingFileFailed": "Uploading '<b>'{fileName}'</b>' failed.",
        "uploadingFileFinished": "Uploading '<b>'{fileName}'</b>' finished."
      },
      "infiniteScroll": {
        "toTop": "Top"
      },
      "journeyActivationModal": {
        "error": {
          "action": "Try again",
          "title": "Journey could not be activated"
        },
        "idle": {
          "cancel": "Cancel",
          "cancelAfterCreation": "Skip for now",
          "description": "You're about to activate journey '<b>'{journeyId}'</b>'",
          "descriptionAfterCreation": "All devices are now prepared. Please install them in the trucks, perform a final security test and close the doors before activating the journey.",
          "title": "Activate journey"
        },
        "processing": {
          "title": "Activating journey"
        },
        "success": {
          "action": "Done",
          "description": "All devices are now activated. The trucks are ready to go and the device is rebooting to test its antenna.",
          "title": "Journey activated"
        }
      },
      "mainTrackerStatus": {
        "tag": "Main tracker update overdue",
        "tooltip": "Last location update main tracker"
      },
      "map": {
        "locationUpdatePopover": {
          "coordinates": "Coordinates",
          "coordinatesUnknown": "Coordinates unknown",
          "locationUpdate": "Location update",
          "speed": "Speed",
          "speedUnknown": "Speed unknown",
          "speedValue": "{speed} km/h",
          "time": "Time",
          "unknown": "Unknown"
        }
      },
      "pagination": {
        "nextPage": "Next page",
        "previousPage": "Previous page"
      },
      "recordAudio": {
        "cancel": "Cancel",
        "connecting": "Connecting",
        "disconnected": "Disconnected",
        "duration": "{duration} min.",
        "failed": "Failed",
        "start": "Start recording",
        "stop": "Stop recording",
        "trailer": "Trailer",
        "truck": "Truck"
      },
      "selectAddress": {
        "loadingMessage": "Loading places...",
        "noMatchesMessage": "No places found.",
        "searchMessage": "Type to search places.",
        "searchPlaceholder": "e.g. Ahlers"
      },
      "selectDevice": {
        "loadingMessage": "Loading available devices...",
        "noMatchesMessage": "No available devices found.",
        "searchMessage": "Type to search available devices.",
        "searchPlaceholder": "e.g. T1"
      },
      "selectEmailUpdateFrequency": {
        "days": "Every {amount, plural, =1 {day} other {# days}}",
        "hours": "Every {amount, plural, =1 {hour} other {# hours}}",
        "minutes": "Every {amount, plural, =1 {minute} other {# minutes}}"
      },
      "selectRoute": {
        "loadingMessage": "Loading routes...",
        "noMatchesMessage": "No routes found.",
        "searchMessage": "Type to search routes.",
        "searchPlaceholder": "e.g. Ahlers"
      },
      "selectTrackerUpdateFrequency": {
        "minutes": "Every {amount, plural, =1 {minute} other {# minutes}}",
        "seconds": "Every {amount, plural, =1 {second} other {# seconds}}"
      }
    },
    "confirm": {
      "abortJourney": {
        "cancel": "Cancel",
        "confirm": "Abort",
        "description": "You're about to abort journey '<b>'{journeyId}'</b>'",
        "title": "Abort journey"
      },
      "deleteCommentLog": {
        "cancel": "Cancel",
        "confirm": "Delete",
        "description": "You're about to delete this comment. Are you sure?",
        "title": "Delete comment"
      },
      "deleteDevice": {
        "cancel": "Cancel",
        "confirm": "Deactivate",
        "description": "You're about to deactivate device '<b>'{name}'</b>'",
        "title": "Deactivate device"
      },
      "deleteRoute": {
        "cancel": "Cancel",
        "confirm": "Delete",
        "description": "You're about to delete route '<b>'{routeNumber}'</b>'",
        "title": "Delete route"
      },
      "deleteUser": {
        "cancel": "Cancel",
        "confirm": "Deactivate",
        "description": "You're about to deactivate user '<b>'{fullName}'</b>'",
        "title": "Deactivate user"
      },
      "dischargeJourney": {
        "cancel": "Cancel",
        "confirm": "Discharge",
        "description": "You're about to discharge journey '<b>'{journeyId}'</b>'",
        "title": "Discharge journey"
      },
      "mergeJourneys": {
        "cancel": "Cancel",
        "confirm": "Confirm",
        "cta": "Please select which details should be used.",
        "description": "The selected journeys will become one journey. Only the selected details will be used, all other information will be removed. This cannot be undone.",
        "title": "Merging"
      },
      "rebootDevice": {
        "cancel": "Cancel",
        "confirm": "Reboot",
        "description": "You're about to reboot device '<b>'{name}'</b>'",
        "title": "Reboot device"
      },
      "rebootDevices": {
        "cancel": "Cancel",
        "confirm": "Reboot",
        "description": "You're about to reboot all selected devices.",
        "title": "Reboot devices"
      },
      "resetPassword": {
        "cancel": "Cancel",
        "confirm": "Reset password",
        "description": "You're about to send a password reset request to '<b>'{fullName}'</b>'",
        "title": "Reset password"
      },
      "sendCustomCommandToDevice": {
        "cancel": "Cancel",
        "confirm": "Send",
        "description": "You're about to send a command to device '<b>'{name}'</b>'",
        "title": "Send command"
      },
      "sendCustomCommandToDevices": {
        "cancel": "Cancel",
        "confirm": "Send",
        "description": "You're about to send a command to all selected devices.",
        "title": "Send command"
      },
      "stopRecording": {
        "cancel": "Stay",
        "confirm": "Leave",
        "description": "By leaving this page, the audio recording will stop.",
        "title": "Stop recording"
      },
      "updateDevice": {
        "cancel": "Cancel",
        "confirm": "Update",
        "description": "You're about to update the firmware of device '<b>'{name}'</b>'",
        "title": "Update firmware"
      },
      "updateDevices": {
        "cancel": "Cancel",
        "confirm": "Update",
        "description": "You're about to update the firmware of all selected devices.",
        "title": "Update firmware"
      }
    },
    "copyright": "&copy; Ahlers 2019",
    "devices": {
      "actions": {
        "bulk": "Bulk actions",
        "rebootDevice": "Reboot",
        "sendCustomCommandToDevice": "Send command",
        "updateDevice": "Update firmware"
      },
      "edit": {
        "backLink": "Devices",
        "title": "Edit device"
      },
      "log": {
        "backLink": "Devices",
        "title": "Log of device set {deviceName}"
      },
      "new": {
        "backLink": "Devices",
        "description": "By creating a new device, the device can be used for journeys.",
        "title": "Create a new device"
      },
      "placeholders": {
        "customCommand": "Type your command here."
      },
      "status": {
        "available": "Available",
        "inUse": "In use"
      }
    },
    "errors": {
      "notFound": {
        "description": "You took a wrong turn somewhere and ended up in no man's land. Don't worry, we'll get you back on route. Just click on the button below to go to the map overview.",
        "link": "To live map",
        "title": "Page not found!"
      }
    },
    "fields": {
      "activeFirmwareVersion": "Firmware",
      "arrivingAt": "Arriving at",
      "backupTracker": "Backup tracker",
      "backupTrackerId": "Backup tracker ID",
      "backupTrackerPhoneNumber": "Backup tracker phone number",
      "braceletPhoneNumber": "Bracelet phone number",
      "clientCompany": "Company",
      "clientEmail": "Email address",
      "clientLocale": "Language",
      "coordinates": "Coordinates",
      "corridor": "Corridor",
      "departingAt": "Departing at",
      "description": "Description",
      "destination": "Destination",
      "destinationAlias": "Destination alias",
      "dischargingAt": "Discharging at",
      "email": "Email address",
      "emailUpdateFrequency": "Email update frequency",
      "firstName": "First name",
      "id": "ID",
      "journeyLogs": "Journey Logs",
      "lastName": "Last name",
      "lastSeen": "Last seen",
      "loadingAt": "Loading at",
      "locale": "Locale",
      "name": "Tracker Name",
      "origin": "Origin",
      "originAlias": "Origin alias",
      "parkings": "Parkings",
      "role": "Role",
      "route": "Route",
      "routeNumber": "Route",
      "sentAt": "Timestamp",
      "sentBy": "Trigger",
      "set": "Set",
      "smartphone": "Smartphone",
      "smartphonePhoneNumber": "Smartphone phone number",
      "smartphoneUid": "Smartphone UID",
      "status": "Status",
      "stopCount": "Stop count",
      "tracker": "Tracker",
      "trackerId": "Tracker ID",
      "trackerPhoneNumber": "Tracker phone number",
      "trackerUpdateFrequency": "Tracker update frequency",
      "trailerPlateNumber": "Trailer",
      "truckPlateNumber": "Truck",
      "type": "Type"
    },
    "journeys": {
      "edit": {
        "backLink": "Journeys",
        "title": "Edit journey"
      },
      "merge": {
        "backLink": "Journeys",
        "cta": "Select the journeys you want to merge.",
        "description": "By merging, multiple active journeys become one journey.",
        "selected": "{amount, plural, =1 {journey} other {journeys}} selected",
        "title": "Merge journeys"
      },
      "new": {
        "backLink": "Journeys",
        "description": "By creating a new journey, you will activate the alarms of all the selected trucks and they will be shown on the map.",
        "title": "Create a new journey"
      },
      "split": {
        "backLink": "Journeys",
        "title": "Split journey"
      },
      "status": {
        "aborted": "Aborted",
        "active": "Active",
        "discharged": "Discharged",
        "merged": "Merged",
        "prepared": "Prepared"
      }
    },
    "map": {
      "detail": {
        "addComment": "Add comment",
        "backLink": "Active trucks",
        "capturePhoto": "Capture photo",
        "eventLogFilterOption": {
          "backupTracker": "Connection - Backup tracker",
          "driverActions": "Driver actions",
          "importantEvents": "Important events",
          "mainTracker": "Connection - Main tracker",
          "other": "Other",
          "smartphone": "Connection - Smartphone"
        },
        "events": "Events",
        "filter": "Filter",
        "recordAudio": "Record audio",
        "showAll": "Show all"
      }
    },
    "movingUnitData": {
      "batteryLevel": {
        "title": "Battery"
      },
      "corridor": {
        "title": "Zone"
      },
      "doors": {
        "closed": "Closed",
        "open": "Open",
        "title": "Doors"
      },
      "locationSource": {
        "backupTracker": "Backup",
        "cellTower": "Cell tower",
        "mainTracker": "Tracker",
        "none": "None",
        "smartphone": "Smartphone",
        "title": "Location"
      },
      "phoneNumber": {
        "title": "Phone number"
      },
      "sos": {
        "no": "No",
        "title": "SOS",
        "yes": "Yes"
      },
      "tracker": {
        "title": "Tracker"
      }
    },
    "movingUnitSettings": {
      "locationSource": {
        "auto": "Auto",
        "backupTracker": "Backup",
        "mainTracker": "Main",
        "title": "Preferred connection"
      }
    },
    "notifications": {
      "audio": {
        "description": "Recording from trailer of truck",
        "title": "Audio recording",
        "trailer": "Recording from trailer",
        "truck": "Recording from truck"
      },
      "backupTrackerBatteryHalfway": {
        "description": "Battery is at 50%",
        "title": "Battery halfway"
      },
      "backupTrackerBatteryInsufficient": {
        "description": "System went into sleep mode",
        "title": "Battery insufficient"
      },
      "backupTrackerBatteryLowLevel": {
        "description": "Battery is at 30%",
        "title": "Battery low"
      },
      "backupTrackerBatteryVeryLowLevel": {
        "description": "Battery is at 15%",
        "title": "Battery low"
      },
      "backupTrackerConnectionLoss": {
        "description": "No communication with truck",
        "title": "Connection lost"
      },
      "backupTrackerConnectionRestored": {
        "description": "Active communication with truck",
        "title": "Connection restored"
      },
      "backupTrackerGpsSignalLoss": {
        "description": "Position will be inaccurate",
        "title": "GPS signal lost"
      },
      "backupTrackerGpsSignalRestored": {
        "description": "Position will be more accurate",
        "title": "GPS signal restored"
      },
      "backupTrackerLimitedConnection": {
        "description": "Updates sent via SMS",
        "title": "Limited connection"
      },
      "backupTrackerLimitedConnectionEstablished": {
        "description": "Limited communication with truck",
        "title": "Connection found"
      },
      "batteryHalfway": {
        "description": "Battery is at 50%",
        "title": "Battery halfway"
      },
      "batteryInsufficient": {
        "description": "System went into sleep mode",
        "title": "Battery insufficient"
      },
      "batteryLowLevel": {
        "description": "Battery is at 30%",
        "title": "Battery low"
      },
      "batteryVeryLowLevel": {
        "description": "Battery is at 15%",
        "title": "Battery low"
      },
      "braceletSos": {
        "description": "Truck driver's bracelet",
        "title": "SOS signal received"
      },
      "cancelStopRequest": {
        "description": "Truck driver canceled the stop",
        "title": "Stop request canceled"
      },
      "comment": {
        "description": "A new comment has been added",
        "edited": "edited",
        "title": "New comment"
      },
      "connectionLoss": {
        "description": "No communication with truck",
        "title": "Connection lost"
      },
      "connectionRestored": {
        "description": "Active communication with truck",
        "title": "Connection restored"
      },
      "convoyConnected": {
        "description": "Truck is in reach",
        "title": "Convoy is connected"
      },
      "convoyViolation": {
        "description": "Trucks are too far apart",
        "title": "Convoy is broken"
      },
      "corridorEntry": {
        "description": "Truck is inside of geofence",
        "title": "Corridor respected"
      },
      "corridorViolation": {
        "description": "Truck is outside of geofence",
        "title": "Corridor violation"
      },
      "deviceError": {
        "description": "Device is not functioning properly",
        "title": "System error"
      },
      "driverBackInRange": {
        "description": "Driver has returned to truck",
        "title": "Driver back in range"
      },
      "driverOutOfRange": {
        "description": "Driver has moved away from truck",
        "title": "Driver out of range"
      },
      "driverSos": {
        "description": "Via smartphone app",
        "title": "SOS signal received"
      },
      "food": {
        "description": "{stopLocationName}",
        "title": "Food stop"
      },
      "foodRequest": {
        "description": "Food consumption",
        "title": "Going to stop"
      },
      "gpsSignalLoss": {
        "description": "Position will be inaccurate",
        "title": "GPS signal lost"
      },
      "gpsSignalRestored": {
        "description": "Position will be more accurate",
        "title": "GPS signal restored"
      },
      "impossibleToCheckConvoy": {
        "description": "We're unable to retrieve convoy information",
        "title": "Impossible to check convoy"
      },
      "journeyActivated": {
        "description": "{name}",
        "title": "Journey activated"
      },
      "limitedConnection": {
        "description": "Updates sent via SMS",
        "title": "Limited connection"
      },
      "limitedConnectionEstablished": {
        "description": "Limited communication with truck",
        "title": "Connection found"
      },
      "mediaMessage": {
        "description": "Photo capture from trailer",
        "title": "Photo capture"
      },
      "photo": {
        "title": "Photo capture"
      },
      "refueling": {
        "description": "{stopLocationName}",
        "title": "Refueling stop"
      },
      "refuelingRequest": {
        "description": "Fuel refill",
        "title": "Going to stop"
      },
      "rest": {
        "description": "{stopLocationName}",
        "title": "Resting stop"
      },
      "restRequest": {
        "description": "Resting",
        "title": "Going to stop"
      },
      "resumedDriving": {
        "description": "In time",
        "title": "Resumed driving"
      },
      "resumedDrivingOvertime": {
        "description": "{minutes, plural, =1 {1 minute} other {# minutes}} overtime",
        "title": "Resumed driving"
      },
      "smartphoneGpsSignalLoss": {
        "description": "The smartphone did not report a location update",
        "title": "Smartphone GPS signal lost"
      },
      "smartphoneGpsSignalRestored": {
        "description": "The smartphone has reported a location update",
        "title": "Smartphone GPS signal restored"
      },
      "suspiciousStop": {
        "description": "Not allowed parking spot",
        "title": "Suspicious stop"
      },
      "switchToBackupDevice": {
        "description": "Switched to backup device",
        "title": "Switched to backup device"
      },
      "switchToPrimaryDevice": {
        "description": "Switched to primary device",
        "title": "Switched to primary device"
      },
      "toilet": {
        "description": "{stopLocationName}",
        "title": "Toilet stop"
      },
      "toiletRequest": {
        "description": "Toilet break",
        "title": "Going to stop"
      },
      "trailerBackInRange": {
        "description": "Trailer has been reconnected to truck",
        "title": "Trailer back in range"
      },
      "trailerIntrusion": {
        "description": "Doors of the trailer are open",
        "gallery": {
          "photoAmount": "{amount, plural, =0 {Awaiting photos} =1 {1 photo} other {# photos}}"
        },
        "title": "Trailer intrusion"
      },
      "trailerOutOfRange": {
        "description": "Trailer has been disconnected from truck",
        "title": "Trailer out of range"
      },
      "turnOffAlarm": {
        "title": "Sound alarm off"
      },
      "turnOnAlarm": {
        "title": "Sound alarm on"
      },
      "unannouncedStop": {
        "description": "Truck driver didn't notify",
        "title": "Unannounced stop"
      }
    },
    "placeholders": {
      "data": {
        "activeJourneys": "No active journeys found.",
        "commandLogs": "No command logs found.",
        "devices": "No devices found.",
        "eventLogs": "No notifications received.",
        "journeys": "No journeys found.",
        "routes": "No routes found.",
        "users": "No users found."
      },
      "datepicker": {
        "arrivingAt": "Select",
        "departingAt": "Select",
        "dischargingAt": "Select",
        "loadingAt": "Select",
        "range": "Select a range",
        "sentAt": "Select a day"
      },
      "fields": {
        "comment": "Type your comment here.",
        "notAvailable": "N/A"
      },
      "input": {
        "backupTrackerId": "Provide backup tracker ID",
        "backupTrackerPhoneNumber": "Provide backup tracker phone number",
        "braceletPhoneNumber": "Provide phone number",
        "clientCompany": "Ahlers",
        "clientEmail": "example@ahlers.com",
        "destinationAlias": "Ahlers",
        "email": "example@ahlers.com",
        "firstName": "Provide first name",
        "lastName": "Provide last name",
        "name": "Provide tracker name",
        "originAlias": "Ahlers",
        "smartphonePhoneNumber": "Provide phone number",
        "smartphoneUid": "Provide smartphone UID",
        "stopCount": "Provide number",
        "trackerId": "Provide tracker ID",
        "trackerPhoneNumber": "Provide phone number",
        "trailerPlateNumber": "License plate",
        "truckPlateNumber": "License plate"
      },
      "search": {
        "coordinates": "Search",
        "description": "Search",
        "deviceLog": "Search on description, trigger, ...",
        "deviceSet": "Search on set",
        "devices": "Search on set, tracker ID, tracker phone number, ...",
        "email": "Search on email address",
        "firstName": "Search on first name",
        "id": "Search on ID",
        "journeys": "Search journeys",
        "lastName": "Search on last name",
        "routeNumber": "Search on route",
        "routes": "Search on route number, origin or destination",
        "sentBy": "Search",
        "trailerPlateNumber": "Search on trailer",
        "truckPlateNumber": "Search on truck",
        "users": "Search on first name, last name and email address"
      },
      "select": {
        "clientLocale": "Select a locale",
        "corridor": "Select a corridor",
        "destination": "Select a destination",
        "device": "Select a device",
        "emailUpdateFrequency": "Select a frequency",
        "locale": "Select a locale",
        "origin": "Select an origin",
        "role": "Select a role",
        "route": "Select a route",
        "status": "Select a status",
        "trackerUpdateFrequency": "Select a frequency",
        "type": "Select a type"
      },
      "upload": {
        "parkings": "No CSV file selected yet."
      }
    },
    "roles": {
      "dispatcher": "Dispatcher",
      "none": "/",
      "security_manager": "Security manager"
    },
    "routes": {
      "edit": {
        "backLink": "Routes",
        "description": "Editing a route won't affect past or active journeys.",
        "title": "Edit route"
      },
      "new": {
        "backLink": "Routes",
        "description": "By creating a new route, the route can be used for journeys.",
        "title": "Create a new route"
      }
    },
    "settings": {
      "map": {
        "speedThresholds": {
          "description": "Change how the map should display a truck driving at a low, normal or high speed.",
          "speedHigh": "High speed '<b>'> {speedNormal} km/h'</b>'",
          "speedLow": "Low speed '<b>'{stopped} - {speedLow} km/h'</b>'",
          "speedNormal": "Normal speed '<b>'{speedLow} - {speedNormal} km/h'</b>'",
          "stopped": "Stopped '<b>'{stopped} km/h'</b>'",
          "title": "Speed thresholds"
        },
        "title": "Map settings"
      },
      "title": "Settings"
    },
    "titles": {
      "activeTrucks": "Active trucks",
      "devices": "Devices",
      "journeys": "Journeys",
      "map": "Map",
      "routes": "Routes",
      "users": "Accounts"
    },
    "users": {
      "edit": {
        "backLink": "Accounts",
        "title": "Edit account"
      },
      "new": {
        "backLink": "Accounts",
        "description": "When creating a new account, an invitation email will be sent to the provided email address.",
        "title": "Create a new account"
      }
    }
  }], ["ru", {
    "actions": {
      "abort": "Отмена",
      "activate": "Применить",
      "addDevice": "Добавить устройство",
      "addJourney": "Добавить рейс",
      "addRoute": "Добавить маршрут",
      "addUser": "Добавить учетную запись",
      "cancel": "Отмена",
      "confirm": "Подтвердить",
      "create": "Создать",
      "deactivate": "Отключить",
      "delete": "Удалить",
      "discharge": "Выгрузка",
      "edit": "Изменить",
      "ignore": "Игнорировать",
      "merge": "Объединить",
      "post": "Отправить",
      "resetPassword": "Сбросить пароль",
      "resolve": "Разрешить",
      "showLog": "Показать логи",
      "split": "Разделить",
      "update": "Обновить",
      "uploadParkings": "Загрузить парковки"
    },
    "appTitle": "Ahlers АМС",
    "auth": {
      "redirecting": "Перенаправление...",
      "signOut": "Выход",
      "signingIn": "Вход...",
      "signingOut": "Выход..."
    },
    "commandLogs": {
      "type": {
        "custom": "СПЕЦИАЛЬНАЯ КОМАНДА",
        "reboot": "ПЕРЕЗАГРУЗКА УСТРОЙСТВА",
        "update_version": "ОБНОВЛЕНИЕ ПРОШИВКИ"
      }
    },
    "components": {
      "actionFeedback": {
        "labelError": "Ошибка",
        "labelProcessing": "Обработка",
        "labelSuccess": "Успешно"
      },
      "alarmStatus": {
        "muted": {
          "description": "Событие вызвало отключение звуковой сигнализации, но звук был отключен. Используйте переключатель, чтобы снова включить звук.",
          "title": "Звуковая сигнализация отключена"
        },
        "on": {
          "description": "Событие вызвало отключение звуковой сигнализации. Вы можете использовать переключатель, чтобы отключить звук.",
          "title": "Звуковая сигнализация выключается"
        }
      },
      "buttonFilter": {
        "active": "Убрать фильтры",
        "inactive": "Фильтр"
      },
      "capturePhoto": {
        "button": "Сфотографировать",
        "labelError": "Ошибка",
        "labelProcessing": "Съемка фото",
        "labelSuccess": "Снято",
        "text": "Фотографирование"
      },
      "editJourneyForm": {
        "clientDetailsTitle": "Данные клиента",
        "trucksTitle": "Грузовики"
      },
      "eventLog": {
        "alert": {
          "statusIcon": {
            "tooltipMessage": {
              "ignored": {
                "dispatcher": "Проигнорирован {name} на {updatedAt}",
                "dispatcherUnknown": "Проигнорирован на {updatedAt}"
              },
              "resolved": {
                "automatically": "Разрешен автоматически на {updatedAt}",
                "dispatcher": "Разрешен {name} на {updatedAt}",
                "dispatcherUnknown": "Разрешен на {updatedAt}"
              }
            }
          }
        }
      },
      "fileUploadButton": {
        "uploading": "Загрузка",
        "uploadingFile": "Загрузка '<b>'{fileName}'</b>'.",
        "uploadingFileFailed": "Ошибка загрузки '<b>'{fileName}'</b>'.",
        "uploadingFileFinished": "Загрузка '<b>'{fileName}'</b>' закончена."
      },
      "infiniteScroll": {
        "toTop": "Вверх"
      },
      "journeyActivationModal": {
        "error": {
          "action": "Попробуйте снова",
          "title": "Рейс не может быть активирован"
        },
        "idle": {
          "cancel": "Отмена",
          "cancelAfterCreation": "Пропустить сейчас",
          "description": "Вы собираетесь активировать рейс '<b>'{journeyId}'</b>'",
          "descriptionAfterCreation": "Все системы готовы. Пожалуйста, установите их в грузовики, проведите окончательные проверки безопасности, закройте двери перед активацией рейса.",
          "title": "Активировать рейс"
        },
        "processing": {
          "title": "Активированное путешествие"
        },
        "success": {
          "action": "Завершено",
          "description": "Все системы теперь активированы. Грузовики готовы к рейсу и системы перезагружаются, для тестирования антенн.",
          "title": "Рейс активирован"
        }
      },
      "mainTrackerStatus": {
        "tag": "Нет связи с основным трекером",
        "tooltip": "Последнее обновление GPS основного трекера"
      },
      "map": {
        "locationUpdatePopover": {
          "coordinates": "Координаты",
          "coordinatesUnknown": "Coordinates unknown",
          "locationUpdate": "Обновление местоположения",
          "speed": "Скорость",
          "speedUnknown": "Скорость неизвестна",
          "speedValue": "{speed} км/ч",
          "time": "Время",
          "unknown": "Неизвестно"
        }
      },
      "pagination": {
        "nextPage": "Следующая страница",
        "previousPage": "Предыдущая страница"
      },
      "recordAudio": {
        "cancel": "Отменить",
        "connecting": "Соединение",
        "disconnected": "Отсоединено",
        "duration": "{duration} мин.",
        "failed": "Ошибка",
        "start": "Начать запись",
        "stop": "Остановить запись",
        "trailer": "Прицеп",
        "truck": "Тягач"
      },
      "selectAddress": {
        "loadingMessage": "Загрузка мест...",
        "noMatchesMessage": "Места не найдены.",
        "searchMessage": "Введите запрос для поиска места.",
        "searchPlaceholder": "например Ahlers"
      },
      "selectDevice": {
        "loadingMessage": "Загрузка доступных устройств ...",
        "noMatchesMessage": "Доступные устройства не найдены.",
        "searchMessage": "Введите для поиска доступных устройств.",
        "searchPlaceholder": "например Т1"
      },
      "selectEmailUpdateFrequency": {
        "days": "Каждый {amount, plural, =1 {день} other {# дней}}",
        "hours": "Каждый {amount, plural, =1 {час} other {# часов}}",
        "minutes": "Каждый {amount, plural, =1 {минут} other {# минут}}"
      },
      "selectRoute": {
        "loadingMessage": "Загрузка маршрутов ...",
        "noMatchesMessage": "Маршруты не найдены.",
        "searchMessage": "Введите запрос для поиска маршрутов.",
        "searchPlaceholder": "например Ahlers"
      },
      "selectTrackerUpdateFrequency": {
        "minutes": "Каждые {amount, plural, =1 {minute} other {# minutes}}",
        "seconds": "Каждые {amount, plural, =1 {second} other {# seconds}}"
      }
    },
    "confirm": {
      "abortJourney": {
        "cancel": "Отмена",
        "confirm": "Отменить",
        "description": "Вы собираетесь отменить рейс '<b>'{journeyId}'</b>'",
        "title": "Отменить рейс"
      },
      "deleteCommentLog": {
        "cancel": "Отмена",
        "confirm": "Удалить",
        "description": "Вы собираетесь удалить этот комментарий. Вы уверены?",
        "title": "Удалить комментарий"
      },
      "deleteDevice": {
        "cancel": "Отмена",
        "confirm": "Деактивировать",
        "description": "Вы собираетесь деактивировать устройство '<b>'{name}'</b>'",
        "title": "Деактивировать устройство"
      },
      "deleteRoute": {
        "cancel": "Отмена",
        "confirm": "Удалить",
        "description": "Вы собираетесь удалить маршрут '<b>'{routeNumber}'</b>'",
        "title": "Удалить маршрут"
      },
      "deleteUser": {
        "cancel": "Отмена",
        "confirm": "Отключить",
        "description": "Вы удаляете пользователя '<b>'{fullName}'</b>'",
        "title": "Удалить пользователя"
      },
      "dischargeJourney": {
        "cancel": "Отмена",
        "confirm": "Выгрузка",
        "description": "Вы собираетесь отметить выгрузку рейса '<b>'{journeyId}'</b>'",
        "title": "Выгрузка рейса"
      },
      "mergeJourneys": {
        "cancel": "Отмена",
        "confirm": "Подтвердить",
        "cta": "Выберите данные рейса, которые будут использованы как основные.",
        "description": "Выбранные рейсы объединятся и станут одним. Только выбранные детали рейса будут использованы, а вся остальная информация будет удалена. Это изменение нельзя отменить.",
        "title": "Объединение"
      },
      "rebootDevice": {
        "cancel": "Отмена",
        "confirm": "Перезагрузка",
        "description": "Вы собираетесь перезагрузить устройство '<b>'{name}'</b>'",
        "title": "Перезагрузите устройство"
      },
      "rebootDevices": {
        "cancel": "Отмена",
        "confirm": "Перезагрузка",
        "description": "Вы собираетесь перезагрузить все выбранные устройства.",
        "title": "Перезагрузите устройства"
      },
      "resetPassword": {
        "cancel": "Отмена",
        "confirm": "Сбросить пароль",
        "description": "Вы планируете отправить запрос на сброс пароля для '<b>'{fullName}'</b>'",
        "title": "Сбросить пароль"
      },
      "sendCustomCommandToDevice": {
        "cancel": "Отмена",
        "confirm": "Отправка",
        "description": "Вы собираетесь отправить команду на устройство '<b>'{name}'</b>'",
        "title": "Отправить команду"
      },
      "sendCustomCommandToDevices": {
        "cancel": "Отмена",
        "confirm": "Отправить",
        "description": "Вы собираетесь отправить команду на все выбранные устройства.",
        "title": "Отправить команду"
      },
      "stopRecording": {
        "cancel": "Остановиться",
        "confirm": "Выйти",
        "description": "Если вы уйдете с этой страницы, запись аудио остановится.",
        "title": "Остановить запись"
      },
      "updateDevice": {
        "cancel": "Отмена",
        "confirm": "Обновление",
        "description": "Вы собираетесь обновить прошивку устройства '<b>'{name}'</b>'",
        "title": "Обновление прошивки"
      },
      "updateDevices": {
        "cancel": "Отмена",
        "confirm": "Обновление",
        "description": "Вы собираетесь обновить прошивку всех выбранных устройств.",
        "title": "Обновление прошивки"
      }
    },
    "copyright": "&copy; Ahlers 2019",
    "devices": {
      "actions": {
        "bulk": "Групповое действие",
        "rebootDevice": "Перезагрузка",
        "sendCustomCommandToDevice": "Отправить команду",
        "updateDevice": "Обновление прошивки"
      },
      "edit": {
        "backLink": "Устройства",
        "title": "Изменить устройство"
      },
      "log": {
        "backLink": "Устройств",
        "title": "Логи комплекта {deviceName}"
      },
      "new": {
        "backLink": "Устройства",
        "description": "Создав новое устройство, вы можете использоваться его для рейсов.",
        "title": "Создать новое устройство"
      },
      "placeholders": {
        "customCommand": "Введите вашу команду здесь."
      },
      "status": {
        "available": "Доступно",
        "inUse": "Используется"
      }
    },
    "errors": {
      "notFound": {
        "description": "Вы ушли с маршрута. Не волнуйтесь, мы вернем вас обратно. Просто нажмите на кнопку ниже, чтобы перейти к обзору карты.",
        "link": "Смотреть карту",
        "title": "Страница не найдена!"
      }
    },
    "fields": {
      "activeFirmwareVersion": "Прошивка",
      "arrivingAt": "Прибытие в",
      "backupTracker": "Резервный трекер",
      "backupTrackerId": "Идентификатор резервного трекера",
      "backupTrackerPhoneNumber": "Номер телефона резервного трекера",
      "braceletPhoneNumber": "Номер телефона браслета",
      "clientCompany": "Компания",
      "clientEmail": "Е-мейл",
      "clientLocale": "Язык",
      "coordinates": "Координаты",
      "corridor": "Коридор",
      "departingAt": "Отправление в",
      "description": "Описание",
      "destination": "Назначение",
      "destinationAlias": "Наименование места назначения",
      "dischargingAt": "Выгрузка в",
      "email": "Адрес электронной почты",
      "emailUpdateFrequency": "Частота обновления по электронной почте",
      "firstName": "Имя",
      "id": "№",
      "journeyLogs": "История поездки",
      "lastName": "Фамилия",
      "lastSeen": "В последний раз был",
      "loadingAt": "Загрузка в",
      "locale": "Язык",
      "name": "Название трекера",
      "origin": "Место отгрузки",
      "originAlias": "Наименование места отгрузки",
      "parkings": "Парковки",
      "role": "Роль",
      "route": "Маршрут",
      "routeNumber": "Маршрут",
      "sentAt": "Дата и время",
      "sentBy": "Триггер",
      "set": "Комплект",
      "smartphone": "Смартфон",
      "smartphonePhoneNumber": "Телефонный номер смартфона",
      "smartphoneUid": "смартфон UID",
      "status": "Статус",
      "stopCount": "Счетчик остановок",
      "tracker": "Трекер",
      "trackerId": "Номер трекера",
      "trackerPhoneNumber": "Телефонный номер трекера",
      "trackerUpdateFrequency": "Частота обновления трекера",
      "trailerPlateNumber": "Прицеп",
      "truckPlateNumber": "Тягач",
      "type": "Тип"
    },
    "journeys": {
      "edit": {
        "backLink": "Рейсы",
        "title": "Редактировать рейс"
      },
      "merge": {
        "backLink": "Рейсы",
        "cta": "Выберите рейсы, которые вы хотите объединить.",
        "description": "При объединении, все рейсы будут преобразованы в один.",
        "selected": "{amount, plural, =1 {путешествие} other {путешествия}} выбраны",
        "title": "Объединение рейсов"
      },
      "new": {
        "backLink": "Рейсы",
        "description": "Создав новый рейс, вы активируете сигналы тревоги всех выбранных грузовиков, и они будут показаны на карте.",
        "title": "Создать новый рейс"
      },
      "split": {
        "backLink": "Рейсы",
        "title": "Разделить рейсы"
      },
      "status": {
        "aborted": "Отменено",
        "active": "Активно",
        "discharged": "Выгружено",
        "merged": "Объединены",
        "prepared": "Приготовлено"
      }
    },
    "map": {
      "detail": {
        "addComment": "Добавить комментарий",
        "backLink": "Активные грузовики",
        "capturePhoto": "Снять фото",
        "eventLogFilterOption": {
          "backupTracker": "Связь - Резервный трекер",
          "driverActions": "Действия водителя",
          "importantEvents": "Важные события",
          "mainTracker": "Связь - Основной трекер",
          "other": "Другое",
          "smartphone": "Связь - Смартфон"
        },
        "events": "События",
        "filter": "Фильтр",
        "recordAudio": "Запись аудио",
        "showAll": "Показать все события"
      }
    },
    "movingUnitData": {
      "batteryLevel": {
        "title": "Батарея"
      },
      "corridor": {
        "title": "Зона"
      },
      "doors": {
        "closed": "Закрыто",
        "open": "Открыто",
        "title": "Двери"
      },
      "locationSource": {
        "backupTracker": "Резервный",
        "cellTower": "Сотовая вышка",
        "mainTracker": "Трекер",
        "none": "Никто",
        "smartphone": "Смартфон",
        "title": "Место расположения"
      },
      "phoneNumber": {
        "title": "Телефонный номер"
      },
      "sos": {
        "no": "Нет",
        "title": "СОС",
        "yes": "Да"
      },
      "tracker": {
        "title": "Трекер"
      }
    },
    "movingUnitSettings": {
      "locationSource": {
        "auto": "Автоматически",
        "backupTracker": "Запасной трекер",
        "mainTracker": "Основной",
        "title": "Предпочитаемое подключение"
      }
    },
    "notifications": {
      "audio": {
        "description": "Recording from trailer of truck",
        "title": "Запись аудио",
        "trailer": "Запись из тягача",
        "truck": "Запись из прицепа"
      },
      "backupTrackerBatteryHalfway": {
        "description": "Аккумулятор на 50%",
        "title": "Аккумулятор  наполовину разряжен"
      },
      "backupTrackerBatteryInsufficient": {
        "description": "Система перешла в спящий режим",
        "title": "Недостаточный заряд аккумулятора"
      },
      "backupTrackerBatteryLowLevel": {
        "description": "Аккумулятор на 30%",
        "title": "Низкий заряд аккумулятора"
      },
      "backupTrackerBatteryVeryLowLevel": {
        "description": "Аккумулятор на 15%",
        "title": "Низкий заряд аккумулятора"
      },
      "backupTrackerConnectionLoss": {
        "description": "Нет связи с грузовиком",
        "title": "Соединение потеряно"
      },
      "backupTrackerConnectionRestored": {
        "description": "Активное соединение с грузовиком",
        "title": "Соединение восстановлено"
      },
      "backupTrackerGpsSignalLoss": {
        "description": "Положение будет неточным",
        "title": "GPS сигнал потерян"
      },
      "backupTrackerGpsSignalRestored": {
        "description": "Положение будет более точным",
        "title": "GPS сигнал восстановлен"
      },
      "backupTrackerLimitedConnection": {
        "description": "Обновления отправляются через SMS",
        "title": "Ограниченная связь"
      },
      "backupTrackerLimitedConnectionEstablished": {
        "description": "Ограниченная связь с грузовиком",
        "title": "Соединение найдено"
      },
      "batteryHalfway": {
        "description": "50% заряда батареи",
        "title": "Половина заряда батареи"
      },
      "batteryInsufficient": {
        "description": "Система перешла в спящий режим",
        "title": "Батарея разряжена"
      },
      "batteryLowLevel": {
        "description": "30% заряда батареи",
        "title": "Низкий заряд батареи"
      },
      "batteryVeryLowLevel": {
        "description": "15% заряда батареи",
        "title": "Низкий заряд батареи"
      },
      "braceletSos": {
        "description": "Браслет водителя грузовика",
        "title": "Сигнал SOS получен"
      },
      "cancelStopRequest": {
        "description": "Водитель грузовика отменил остановку",
        "title": "Запрос остановки отменен"
      },
      "comment": {
        "description": "Добавлен новый комментарий",
        "edited": "изменено",
        "title": "Новый комментарий"
      },
      "connectionLoss": {
        "description": "Нет связи с грузовиком",
        "title": "Связь потеряна"
      },
      "connectionRestored": {
        "description": "Активное соединение с грузовиком",
        "title": "Соединение восстановлено"
      },
      "convoyConnected": {
        "description": "Грузовик в пределах досягаемости",
        "title": "Конвой соединен"
      },
      "convoyViolation": {
        "description": "Грузовики слишком далеко друг от друга",
        "title": "Конвой нарушен"
      },
      "corridorEntry": {
        "description": "Грузовик внутри геозоны",
        "title": "Коридор соблюдается"
      },
      "corridorViolation": {
        "description": "Грузовик за пределами геозоны",
        "title": "Нарушение коридора"
      },
      "deviceError": {
        "description": "Устройство работает неправильно",
        "title": "Ошибка системы"
      },
      "driverBackInRange": {
        "description": "Водитель вернулся в грузовик",
        "title": "Водитель вернулся в зону доступа"
      },
      "driverOutOfRange": {
        "description": "Водитель отошел от грузовика",
        "title": "Водитель вне доступа"
      },
      "driverSos": {
        "description": "Через приложение смартфона",
        "title": "Получен сигнал SOS"
      },
      "food": {
        "description": "{stopLocationName}",
        "title": "Остановка на обед"
      },
      "foodRequest": {
        "description": "Обед",
        "title": "Планирую остановку"
      },
      "gpsSignalLoss": {
        "description": "Позиционирование будет неточным",
        "title": "Потерян сигнал GPS"
      },
      "gpsSignalRestored": {
        "description": "Позиционирование будет более точным",
        "title": "Сигнал GPS восстановлен"
      },
      "impossibleToCheckConvoy": {
        "description": "Мы не можем получить информацию о конвое",
        "title": "Невозможно проверить колонну"
      },
      "journeyActivated": {
        "description": "{name}",
        "title": "Рейс активирован"
      },
      "limitedConnection": {
        "description": "Обновления отправляются через SMS",
        "title": "Ограниченная связь"
      },
      "limitedConnectionEstablished": {
        "description": "Ограниченная связь с грузовиком",
        "title": "Соединение восстановлено"
      },
      "mediaMessage": {
        "description": "Photo capture from trailer",
        "title": "Photo capture"
      },
      "photo": {
        "title": "Снять фото"
      },
      "refueling": {
        "description": "{stopLocationName}",
        "title": "Остановка на заправку"
      },
      "refuelingRequest": {
        "description": "Заправка",
        "title": "Планирую остановку"
      },
      "rest": {
        "description": "{stopLocationName}",
        "title": "Остановка на отдых"
      },
      "restRequest": {
        "description": "Отдых",
        "title": "Планирую остановку"
      },
      "resumedDriving": {
        "description": "Вовремя",
        "title": "Возобновление движения"
      },
      "resumedDrivingOvertime": {
        "description": "{minutes, plural, =1 {1 минута} other {# минут}} овертайма",
        "title": "Возобновление движения"
      },
      "smartphoneGpsSignalLoss": {
        "description": "Смартфон не обновил местоположение",
        "title": "Сигнал GPS смартфона потерян"
      },
      "smartphoneGpsSignalRestored": {
        "description": "Смартфон обновил местоположение",
        "title": "Сигнал GPS смартфона восстановлен"
      },
      "suspiciousStop": {
        "description": "Неразрешенное парковочное место",
        "title": "Подозрительная остановка"
      },
      "switchToBackupDevice": {
        "description": "Переключено на запасной трекер",
        "title": "Переключено на запасной трекер"
      },
      "switchToPrimaryDevice": {
        "description": "Переключено на основную систему",
        "title": "Переключено на основную систему"
      },
      "toilet": {
        "description": "{stopLocationName}",
        "title": "Остановка, туалет"
      },
      "toiletRequest": {
        "description": "Остановка, туалет",
        "title": "Планирую остановку"
      },
      "trailerBackInRange": {
        "description": "Прицеп был переподключен к грузовику",
        "title": "Грузовик вернулся в зону доступа"
      },
      "trailerIntrusion": {
        "description": "Двери прицепа открыты",
        "gallery": {
          "photoAmount": "{amount, plural, =0 {Ожидание фотографий} =1 {1 фото} other {# фото}}"
        },
        "title": "Вскрытие прицепа"
      },
      "trailerOutOfRange": {
        "description": "Прицеп был отключен от тягача",
        "title": "Прицеп вне зоны доступа"
      },
      "turnOffAlarm": {
        "title": "Сирена выключена"
      },
      "turnOnAlarm": {
        "title": "Сирена включена"
      },
      "unannouncedStop": {
        "description": "Водитель грузовика не уведомил",
        "title": "Необъявленная остановка"
      }
    },
    "placeholders": {
      "data": {
        "activeJourneys": "Активные рейсы не найдены.",
        "commandLogs": "Логи команд не найдены.",
        "devices": "Устройства не найдены.",
        "eventLogs": "Уведомления не получены.",
        "journeys": "Рейсы не найдены.",
        "routes": "Маршруты не найдены.",
        "users": "Пользователи не найдены."
      },
      "datepicker": {
        "arrivingAt": "Выбрать",
        "departingAt": "Выбрать",
        "dischargingAt": "Выбрать",
        "loadingAt": "Выбрать",
        "range": "Выбрать уровень",
        "sentAt": "Выберите день"
      },
      "fields": {
        "comment": "Добавьте комментарии здесь.",
        "notAvailable": "Не определен"
      },
      "input": {
        "backupTrackerId": "Укажите идентификатор запасного трекера",
        "backupTrackerPhoneNumber": "Укажите номер телефона запасного трекера",
        "braceletPhoneNumber": "Укажите телефонный номер",
        "clientCompany": "Алерс",
        "clientEmail": "example@ahlers.com",
        "destinationAlias": "Алерс",
        "email": "example@ahlers.com",
        "firstName": "Укажите имя",
        "lastName": "Укажите фамилию",
        "name": "Укажите название устройства",
        "originAlias": "Алерс",
        "smartphonePhoneNumber": "Укажите номер телефона",
        "smartphoneUid": "Укажите UID смартфона",
        "stopCount": "Укажите номер",
        "trackerId": "Укажите ID трекера",
        "trackerPhoneNumber": "Укажите номер телефона",
        "trailerPlateNumber": "Госномер",
        "truckPlateNumber": "Госномер"
      },
      "search": {
        "coordinates": "Поиск",
        "description": "Поиск",
        "deviceLog": "Искать по описанию, триггеру, ...",
        "deviceSet": "Поиск по комплекту",
        "devices": "Поиск по комплекту, идентификатор трекера, номер телефона трекера, ...",
        "email": "Искать адрес электронной почты",
        "firstName": "Искать по имени",
        "id": "Искать по номеру",
        "journeys": "Поиск рейса",
        "lastName": "Искать по фамилии",
        "routeNumber": "Искать по маршруту",
        "routes": "Искать по номеру маршрута, месту отправки или назначения",
        "sentBy": "Поиск",
        "trailerPlateNumber": "Искать по прицепу",
        "truckPlateNumber": "Искать по тягачу",
        "users": "Искать имя, фамилию и адрес электронной почты"
      },
      "select": {
        "clientLocale": "Выберите локальный",
        "corridor": "Выбрать коридор",
        "destination": "Выбрать пункт назначения",
        "device": "Выбрать систему",
        "emailUpdateFrequency": "Выберите частоту",
        "locale": "Выберите местный",
        "origin": "Выбрать место погрузки",
        "role": "Выбрать роль",
        "route": "Выбрать маршрут",
        "status": "Выбрать статус",
        "trackerUpdateFrequency": "Выберите частоту",
        "type": "Выберите тип"
      },
      "upload": {
        "parkings": "Файл CSV не выбран."
      }
    },
    "roles": {
      "dispatcher": "Диспетчер",
      "none": "/",
      "security_manager": "Менеджер по безопасности"
    },
    "routes": {
      "edit": {
        "backLink": "Маршруты",
        "description": "Редактирование маршрута не повлияет на прошлые или активные поездки.",
        "title": "Изменить маршрут"
      },
      "new": {
        "backLink": "Маршруты",
        "description": "Создав новый маршрут, вы можете использовать его для рейсов.",
        "title": "Создать новый маршрут"
      }
    },
    "settings": {
      "map": {
        "speedThresholds": {
          "description": "Измените способ отображения на карте грузовика, движущегося на низкой, нормальной или высокой скорости.",
          "speedHigh": "Высокая скорость '<b>'> {speedNormal} км/ч'</b>'",
          "speedLow": "Низкая скорость '<b>'{stopped} - {speedLow} км/ч'</b>'",
          "speedNormal": "Нормальная скорость '<b>'{speedLow} - {speedNormal} км/ч'</b>'",
          "stopped": "Остановлен '<b>'{stopped} км/ч'</b>'",
          "title": "Пороги скорости"
        },
        "title": "Настройки карты"
      },
      "title": "Настройки"
    },
    "titles": {
      "activeTrucks": "Активные грузовики",
      "devices": "Приборы",
      "journeys": "Рейсы",
      "map": "Карта",
      "routes": "Маршруты",
      "users": "Учетные записи"
    },
    "users": {
      "edit": {
        "backLink": "Учетные записи",
        "title": "Изменение учетной записи"
      },
      "new": {
        "backLink": "Учетные записи",
        "description": "При создании новой учетной записи, на указанный адрес электронной почты будет отправлено письмо с приглашением.",
        "title": "Создать новую учетную запись"
      }
    }
  }]];
  _exports.default = _default;
});