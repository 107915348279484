define("ember-presentational-table/components/presentational-table/head/row/cell/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _object, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th
    {{on "click" this.clickHandler}}
    class={{concat
      this.classes.theadCell
      (if this.isSortable (concat " " this.classes.theadCellIsSortable))
      (if this.isSorted (concat " " this.classes.theadCellIsSorted))
      (if this.isSortedAsc (concat " " this.classes.theadCellIsSortedAsc))
      (if this.isSortedDesc (concat " " this.classes.theadCellIsSortedDesc))
    }}
    role="button"
    ...attributes
  >
    {{yield
      (hash
        isSorted=this.isSorted
        isSortedAsc=this.isSortedAsc
        isSortedDesc=this.isSortedDesc
      )
    }}
  </th>
  */
  {
    "id": "roKDVpos",
    "block": "[[[11,\"th\"],[16,0,[28,[37,0],[[30,0,[\"classes\",\"theadCell\"]],[52,[30,0,[\"isSortable\"]],[28,[37,0],[\" \",[30,0,[\"classes\",\"theadCellIsSortable\"]]],null]],[52,[30,0,[\"isSorted\"]],[28,[37,0],[\" \",[30,0,[\"classes\",\"theadCellIsSorted\"]]],null]],[52,[30,0,[\"isSortedAsc\"]],[28,[37,0],[\" \",[30,0,[\"classes\",\"theadCellIsSortedAsc\"]]],null]],[52,[30,0,[\"isSortedDesc\"]],[28,[37,0],[\" \",[30,0,[\"classes\",\"theadCellIsSortedDesc\"]]],null]]],null]],[24,\"role\",\"button\"],[17,1],[4,[38,2],[\"click\",[30,0,[\"clickHandler\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,4],null,[[\"isSorted\",\"isSortedAsc\",\"isSortedDesc\"],[[30,0,[\"isSorted\"]],[30,0,[\"isSortedAsc\"]],[30,0,[\"isSortedDesc\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"if\",\"on\",\"yield\",\"hash\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/head/row/cell/index.hbs",
    "isStrictMode": false
  });
  const ASC = 'asc';
  const DESC = 'desc';
  let PresentationalTableHeadRowCellComponent = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), (_class = class PresentationalTableHeadRowCellComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "classes", _descriptor, this);
      _initializerDefineProperty(this, "isSortable", _descriptor2, this);
      _initializerDefineProperty(this, "onSort", _descriptor3, this);
      _initializerDefineProperty(this, "sort", _descriptor4, this);
      _initializerDefineProperty(this, "sortField", _descriptor5, this);
    }
    get isSorted() {
      return Boolean(this.sort[this.sortField]);
    }
    get isSortedAsc() {
      return this.sort[this.sortField] === ASC;
    }
    get isSortedDesc() {
      return this.sort[this.sortField] === DESC;
    }
    clickHandler(event) {
      if (this.isSortable === false) {
        return;
      }
      let newSort = {};
      const isSelectingMultiple = event.metaKey;
      if (isSelectingMultiple) {
        newSort = {
          ...this.sort
        };
      }
      newSort[this.sortField] = this.isSortedAsc ? DESC : ASC;
      this.onSort(newSort);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "classes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSortable", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onSort", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return () => {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sort", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sortField", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "clickHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clickHandler"), _class.prototype)), _class));
  _exports.default = PresentationalTableHeadRowCellComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PresentationalTableHeadRowCellComponent);
});