define("ember-request/services/request", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const METHOD = {
    DELETE: 'DELETE',
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT'
  };
  class RequestService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "headers", null);
      _defineProperty(this, "host", '');
      _defineProperty(this, "namespace", '');
    }
    /**
     * State
     */
    /**
     * Config Methods
     */

    setHeader(headerKey, headerValue) {
      const headers = {
        ...this.headers
      };
      headers[headerKey] = headerValue;
      this.set('headers', headers);
    }
    setHost(host) {
      this.set('host', host);
    }
    setNamespace(namespace) {
      this.set('namespace', namespace);
    }

    /**
     * Request Methods
     */

    del(path, options) {
      return this.performRequest(path, {
        method: METHOD.DELETE,
        options
      });
    }
    patch(path, options) {
      return this.performRequest(path, {
        method: METHOD.PATCH,
        options
      });
    }
    post(path, options) {
      return this.performRequest(path, {
        method: METHOD.POST,
        options
      });
    }
    put(path, options) {
      return this.performRequest(path, {
        method: METHOD.PUT,
        options
      });
    }
    request(path, options) {
      return this.performRequest(path, {
        method: METHOD.GET,
        options
      });
    }

    /**
     * Private Methods
     */

    performRequest(path, _ref) {
      let {
        method = METHOD.GET,
        options = {}
      } = _ref;
      const {
        body,
        headers,
        host,
        namespace,
        searchParams
      } = options;
      const requestUrl = this.createRequestUrl(path, {
        host,
        namespace,
        searchParams
      });
      const requestOptions = {
        body: this.createRequestBody(body),
        headers: this.createRequestHeaders(headers),
        method
      };
      return new Promise((resolve, reject) => {
        fetch(requestUrl, requestOptions).then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      });
    }
    createRequestUrl(path, _ref2) {
      let {
        host,
        namespace,
        searchParams
      } = _ref2;
      const isFullUrl = path.startsWith('http');
      if (isFullUrl) {
        return path;
      }
      const requestHost = isDefined(host) ? host : this.host;
      const requestNamespace = isDefined(namespace) ? namespace : this.namespace;
      const requestPath = path;
      const requestSearch = createSearch(searchParams);
      const requestUrlParts = [];
      if (requestHost) {
        requestUrlParts.push(requestHost);
      }
      if (requestNamespace) {
        requestUrlParts.push(requestNamespace);
      }
      if (requestPath) {
        requestUrlParts.push(requestPath);
      }
      const requestUrl = requestUrlParts.join('/');
      if (!requestSearch) {
        return requestUrl;
      }
      if (hasSearch(requestUrl)) {
        return requestUrl + `&${requestSearch}`;
      }
      return requestUrl + `?${requestSearch}`;
    }
    createRequestBody(body) {
      return body ? JSON.stringify(body) : null;
    }
    createRequestHeaders(headers) {
      return {
        ...this.headers,
        ...headers
      };
    }
  }
  _exports.default = RequestService;
  function createSearch() {
    let searchParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const search = [];
    Object.keys(searchParams).forEach(key => {
      search.push(`${key}=${searchParams[key]}`);
    });
    return search.join('&');
  }
  function hasSearch(url) {
    return url.includes('?');
  }
  function isDefined(variable) {
    return typeof variable !== 'undefined';
  }
});