define("ember-data-record-meta/helpers/record-meta", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object", "@ember/object/events", "@ember/service", "@ember-data/model", "ember-data-record-meta/-private/config", "ember-data-record-meta/-private/utils"], function (_exports, _helper, _debug, _object, _events, _service, _model, _config, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RecordMetaHelper = (_dec = (0, _service.inject)('record-meta'), (_class = class RecordMetaHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "recordMetaService", _descriptor, this);
      _defineProperty(this, "modelName", null);
      _defineProperty(this, "recordId", null);
      (0, _events.addListener)(this.recordMetaService, _config.EVENT.RECORD_META_CHANGED, this.recordMetaChangedHandler);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _events.removeListener)(this.recordMetaService, _config.EVENT.RECORD_META_CHANGED, this.recordMetaChangedHandler);
    }
    compute(_ref) {
      let [record] = _ref;
      assertRecord(record);
      this.cacheRecordProperties(record);
      return this.recordMetaService.getRecordMeta(this.modelName, this.recordId);
    }
    recordMetaChangedHandler(modelName, recordId) {
      if (modelName === this.modelName && recordId === this.recordId) {
        this.recompute();
      }
    }
    cacheRecordProperties(record) {
      this.modelName = record.constructor.modelName;
      this.recordId = record.id;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "recordMetaService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "recordMetaChangedHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "recordMetaChangedHandler"), _class.prototype)), _class));
  _exports.default = RecordMetaHelper;
  function assertRecord(record) {
    (true && !(record instanceof _model.default) && (0, _debug.assert)((0, _utils.assertMessage)(`Record must be an instance of "@ember-data/model". "${record}" provided.`), record instanceof _model.default));
  }
});