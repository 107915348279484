define("ember-google-maps/components/g-map/route", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/typical-map-component"], function (_exports, _component, _templateFactory, _typicalMapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash directions=@directions)}}
  */
  {
    "id": "zvlzwWGH",
    "block": "[[[18,2,[[28,[37,1],null,[[\"directions\"],[[30,1]]]]]]],[\"@directions\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-google-maps/components/g-map/route.hbs",
    "isStrictMode": false
  });
  class Route extends _typicalMapComponent.default {
    get name() {
      return 'routes';
    }
    get newOptions() {
      var _this$options$directi;
      if (((_this$options$directi = this.options.directions) === null || _this$options$directi === void 0 ? void 0 : _this$options$directi.status) !== 'OK') {
        return {};
      }
      return this.options;
    }
    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.DirectionsRenderer(options);
    }
  }
  _exports.default = Route;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Route);
});