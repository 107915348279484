define("ember-presentational-table/components/presentational-table/body/row/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class={{@classes.tbodyRow}} ...attributes>
    {{yield
      (hash Cell=(component "presentational-table/body/row/cell" classes=@classes))
    }}
  </tr>
  */
  {
    "id": "S1U9od6/",
    "block": "[[[11,\"tr\"],[16,0,[30,1,[\"tbodyRow\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"Cell\"],[[50,\"presentational-table/body/row/cell\",0,null,[[\"classes\"],[[30,1]]]]]]]]],[1,\"\\n\"],[13]],[\"@classes\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/body/row/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});