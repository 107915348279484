define("ember-presentational-table/components/presentational-table/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <table class={{@classes.table}} ...attributes>
    {{yield
      (hash
        Body=(component "presentational-table/body" classes=@classes data=@data)
        Head=(component "presentational-table/head" classes=@classes onSort=@onSort sort=@sort)
      )
    }}
  </table>
  */
  {
    "id": "15vTpGBX",
    "block": "[[[11,\"table\"],[16,0,[30,1,[\"table\"]]],[17,2],[12],[1,\"\\n  \"],[18,6,[[28,[37,1],null,[[\"Body\",\"Head\"],[[50,\"presentational-table/body\",0,null,[[\"classes\",\"data\"],[[30,1],[30,3]]]],[50,\"presentational-table/head\",0,null,[[\"classes\",\"onSort\",\"sort\"],[[30,1],[30,4],[30,5]]]]]]]]],[1,\"\\n\"],[13]],[\"@classes\",\"&attrs\",\"@data\",\"@onSort\",\"@sort\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});