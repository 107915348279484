define("ember-data-record-meta/-private/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EVENT = void 0;
  const EVENT = {
    RECORD_META_CHANGED: 'record-meta-changed'
  };
  _exports.EVENT = EVENT;
});