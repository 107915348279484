define("ember-data-record-meta/services/record-meta", ["exports", "@ember/application", "@ember/debug", "@ember/object/events", "@ember/service", "ember-data-record-meta/-private/config", "ember-data-record-meta/-private/utils"], function (_exports, _application, _debug, _events, _service, _config, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class RecordMetaService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "recordMetaMap", {});
    }
    setRecordMeta(modelName, recordId, recordMeta) {
      assertModelName(modelName, this);
      assertRecordId(recordId);
      assertRecordMeta(recordMeta);
      this.recordMetaMap = {
        ...this.recordMetaMap,
        [modelName]: {
          ...this.recordMetaMap[modelName],
          [recordId]: recordMeta
        }
      };
      (0, _events.sendEvent)(this, _config.EVENT.RECORD_META_CHANGED, [modelName, recordId]);
    }
    getRecordMeta(modelName, recordId) {
      assertModelName(modelName, this);
      assertRecordId(recordId);
      const recordMeta = this.recordMetaMap[modelName]?.[recordId];
      if (recordMeta) {
        return recordMeta;
      }
      return null;
    }
    normalizeRecordMeta(modelClass, payload) {
      let {
        keyTransform
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let recordMeta = payload.meta;
      if (!recordMeta) {
        return;
      }
      if (keyTransform) {
        recordMeta = transformRecordMetaKeys(recordMeta, keyTransform);
      }
      const modelName = modelClass.modelName;
      const recordId = payload.id;
      this.setRecordMeta(modelName, recordId, recordMeta);
    }
  }
  _exports.default = RecordMetaService;
  function transformRecordMetaKeys(recordMeta, keyTransform) {
    assertKeyTransform(keyTransform);
    return Object.keys(recordMeta).reduce((recordMetaTransformed, key) => {
      recordMetaTransformed[keyTransform(key)] = recordMeta[key];
      return recordMetaTransformed;
    }, {});
  }
  function assertKeyTransform(keyTransform) {
    (true && !(typeof keyTransform === 'function') && (0, _debug.assert)((0, _utils.assertMessage)('"keyTransform" must be a function.'), typeof keyTransform === 'function'));
  }
  function assertModelName(modelName, service) {
    (true && !((0, _application.getOwner)(service).factoryFor(`model:${modelName}`)) && (0, _debug.assert)((0, _utils.assertMessage)(`No model was found named "${modelName}".`), (0, _application.getOwner)(service).factoryFor(`model:${modelName}`)));
  }
  function assertRecordId(recordId) {
    (true && !(typeof recordId === 'string' || typeof recordId === 'number') && (0, _debug.assert)((0, _utils.assertMessage)('The "recordId" argument must be a string or a number.'), typeof recordId === 'string' || typeof recordId === 'number'));
  }
  function assertRecordMeta(recordMeta) {
    (true && !(typeof recordMeta === 'object') && (0, _debug.assert)((0, _utils.assertMessage)('The "recordMeta" argument must be an object.'), typeof recordMeta === 'object'));
  }
});