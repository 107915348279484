define("ember-presentational-table/components/presentational-table/body/row/cell/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class={{@classes.tbodyCell}} ...attributes>
    {{yield}}
  </td>
  */
  {
    "id": "H8Fa+YtJ",
    "block": "[[[11,\"td\"],[16,0,[30,1,[\"tbodyCell\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@classes\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-presentational-table/components/presentational-table/body/row/cell/index.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});